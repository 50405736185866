<template>
  <div class="review-his" v-if="showId == 'review'">
    <div class="his-title">农事记录</div>
    <div class="his-main">
      <v-chart class="his-charts" autoresize :option="chartOption"></v-chart>
      <div class="his-list" v-show="trance_data.length">
        <div class="his-items" v-for="item in trance_data" :key="item.id">
          <div class="his-items-date">{{ item.date }}</div>
          <div class="his-items-main">
            <div class="his-main-cells">
              <span class="cells-title">{{ item.start_time }}</span>
              <span class="cells-content-tips">{{ item["流程"] }}</span>
            </div>
            <div class="his-main-cells">
              <span class="cells-title">地块</span>
              <span class="cells-content one-txt-cut">{{ item["地块"] }}</span>
            </div>
            <!-- <div class="his-main-cells">
              <span class="cells-title">面积(亩)</span>
              <span class="cells-content one-txt-cut">100</span>
            </div> -->
            <div class="his-main-cells">
              <span class="cells-title">负责人</span>
              <span class="cells-content one-txt-cut">{{
                item["操作员"]
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <el-empty
        description="暂无数据"
        :image-size="60"
        v-show="!trance_data.length"
      ></el-empty>
    </div>
  </div>
</template>

<script>
import VChart, { THEME_KEY } from "vue-echarts";
import { getHanshanTranceListApi } from "@/request/api.js";
export default {
  name: "reviewHis",
  provide: {
    [THEME_KEY]: "dark",
  },
  components: {
    VChart,
  },
  props: {
    showId: {
      type: String,
      default: "",
    },
    plotId: {
      type: [String, Number],
      default: 0,
    },
  },

  data() {
    return {
      trance_data: [],
      chartOption: {
        backgroundColor: "transparent",
        tooltip: {
          trigger: "item",
        },
        // grid: {
        //   left: "10%",
        //   top: "10%",
        //   right: "10%",
        //   bottom: "50%",
        // },
        legend: {
          bottom: 0,
          left: "center",
          type: "scroll",
        },
        series: [
          {
            type: "pie",
            radius: ["45%", "60%"],
            center: ["50%", "38%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
        ],
      },
    };
  },
  created() {},
  watch: {
    plotId() {
      this.getHanShanTranceList();
    },
  },
  methods: {
    async getHanShanTranceList() {
      this.chartOption.series[0].data = [];
      const { code, results } = await getHanshanTranceListApi({
        plot_id: this.plotId,
      });
      if (code !== 0) return;
      results.trance_data.forEach((item) => {
        item["date"] = item["记录时间"].slice(0, 7);
        item["start_time"] = item["记录时间"].slice(5, 10);
      });
      this.trance_data = results.trance_data;
      const keyArr = Object.keys(results.counter);
      const valueArr = Object.values(results.counter);
      keyArr.forEach((t, i) => {
        const obj = {
          name: "",
          value: "",
        };
        obj.name = t;
        obj.value = valueArr[i];
        this.chartOption.series[0].data.push(obj);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.review-his {
  width: 315px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  background-color: #0f243d;
  display: flex;
  flex-direction: column;
  transition: all 0.5s;

  .his-title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    background-color: rgba(21, 92, 165, 0.9);
    padding: 0 10px;
    border-radius: 5px 5px 0px 0px;
  }
  .his-main {
    padding: 0 9px;
    .his-charts {
      width: 100%;
      height: 200px;
    }
    .his-list {
      width: 100%;
      height: 360px;
      overflow: auto;
      background-color: #0f243d;
      display: flex;
      flex-direction: column;
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #3e90e5;
      }
      .his-items {
        width: 100%;
        margin-bottom: 11px;
        background-color: #0e2036;
        &:last-of-type {
          margin-bottom: 0;
        }
        .his-items-date {
          width: 100%;
          height: 27px;
          line-height: 27px;
          border-bottom: 1px solid #0f243d;
          padding: 0 18px;
          font-size: 14px;
          font-weight: 400;
          color: #fff;
        }
        .his-items-main {
          width: 100%;
          display: flex;
          height: 55px;
          .his-main-cells {
            width: 33.33%;
            height: 100%;
            font-size: 12px;
            font-weight: 400;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .cells-title {
              color: #fff;
            }
            .cells-content {
              width: 100%;
              color: #409eff;
              display: block;
              text-align: center;
            }
            .cells-content-tips {
              min-width: 34px;
              height: 17px;
              background: #155ca5;
              padding: 0 2px;
              line-height: 17px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
</style>
